import React, { useState } from "react"
import classnames from "classnames"

import { motion, AnimatePresence } from "framer-motion"
import elementTransitionAnimation from "~animations/elementTransition"

/* Import Global Hook(s) */
import { useNewsPage, useNewsPosts } from "~queries/hooks"

/* Import Global Component(s) */
import Footer from "~components/footer/footer"
import Page from "~components/page/page"
import PageHeader from "~components/page/components/pageHeader/pageHeader"
import PageArticle from "~components/page/components/pageArticle/pageArticle"
import PageArticleSection from "~components/page/components/pageArticle/components/pageArticleSection/pageArticleSection"
import Slideshow from "~components/slideshow/slideshow"

/* Import Page Components */
import NewsItemCard from "~components/pages/news/components/newsItemCard/newsItemCard"

/* Import Local Style(s) */
import "./news.scss"

const News = ({ location }) => {
  const { pageTitle, slideshow } = useNewsPage()
  const newsPostsByYear = useNewsPosts()
  const [yearIndex, setYearIndex] = useState(0)

  return (
    <Page title={pageTitle} location={location}>
      <PageHeader title={pageTitle} location={location} />
      <PageArticle title={pageTitle} className="news">
        {slideshow.images.length > 0 ? (
          <Slideshow slideshow={slideshow} />
        ) : null}
        <PageArticleSection className="news">
          <div className="news--grid">
            {newsPostsByYear[0].posts.map(({ _id, publicationDate, title, externalLink, source }) => (
              <NewsItemCard date={publicationDate} title={title} externalLink={externalLink} source={source} key={_id} />
            ))}  
          </div>
          
          <div className="time-display">
            <ul className="years">
              <li className="font-size--small">Past Years: </li>
              {newsPostsByYear.map((p, i) => ( (i > 0) ?
                <li key={p.year.toString()} className="font-size--small" ><button
                  className={classnames({ active: yearIndex === i })}
                  onClick={() => {
                    (yearIndex === i) ? setYearIndex(0) : setYearIndex(i)
                  }}
                >{p.year}</button></li>
              : ''))}
            </ul>
          </div>

          <AnimatePresence exitBeforeEnter>
            {(yearIndex !== 0 && <motion.div
              key="grid"
              initial="initial"
              animate="animate"
              exit="exit"
              className="news--grid"
              variants={elementTransitionAnimation.variants}
            >
              {(yearIndex !== 0) ? newsPostsByYear[yearIndex].posts.map(({ _id, publicationDate, title }) => (
                <NewsItemCard date={publicationDate} title={title} key={_id} />
              )) : ''}
            </motion.div>)}
          </AnimatePresence>


        </PageArticleSection>
      </PageArticle>
      <Footer />
    </Page>
  )
}

export default News
