import React, { useContext } from "react"

/* Import Local Style(s) */
import "./youtube.scss"

const YoutubeEmbed = ({embed = ''}) => {

	return (<div className="youtube-embed">
		<div className="youtube-embed-iframe-wrapper" dangerouslySetInnerHTML={{ __html: embed}}></div>
	</div>)

}

export default YoutubeEmbed